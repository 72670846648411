<template>
  <div class="c-container c-container--small">
    <Tile class="p-8 mb-10">
      <div class="flex items-center w-full">
        <MultiSelect :items="userList" label="Streamers" :required="true" @selectedItems="setCheckedStreamers" />
      </div>
    </Tile>

    <div class="flex justify-center w-full">
      <Button kind="link" class="mr-10" :url="'/create/campaign/settings'" label="Previous step" />

      <Button kind="primary" label="Next step" @clicked="nextStep" />
    </div>

    <transition name="slide-up">
      <Popup v-if="campaign && campaign.handles.length" :position="['bottom', 'right']">
        <div class="p-6 popup__header">
          <span class="popup__amount">{{ campaign.handles.length }}</span>

          <span class="font-medium">Streamers</span>

          <Button class="ml-auto" @clicked="togglePopup">
            {{ showStreamerList ? "Hide" : "Show" }}
          </Button>
        </div>

        <ul v-if="showStreamerList" class="popup__list">
          <li v-for="(streamer, i) in campaign.handles" :key="i" class="popup__list-item">
            <div class="flex flex-col">
              <span class="mb-2 text-sm">@{{ streamer.handle }}</span>

              <span class="text-sm capitalize color--blue-lighter">{{ streamer.platform }}</span>
            </div>

            <Button class="ml-auto" kind="link" label="Remove" @clicked="removeStreamer(i)" />
          </li>
        </ul>
      </Popup>
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapMutations } from "vuex";
import { Button } from "cavea-design-system";
import log from "@/lib/log";

export default {
  name: "CreateCampaignStreamers",

  metaInfo: {
    title: "Create Campaign Settings",
  },

  components: {
    Tile: () => import("@/components/Tile"),
    Button,
    Popup: () => import("@/components/Popup"),
    MultiSelect: () => import("@/components/inputs/MultiSelect"),
  },

  data() {
    return {
      campaign: null,
      handle: "",
      showStreamerList: true,
      userList: [],
    };
  },

  computed: {
    ...mapGetters(["getUserInfo", "getCampaign", "getOrganization"]),
  },

  watch: {
    campaign: {
      deep: true,

      handler() {
        this.setCampaign(this.campaign);
      },
    },
  },

  mounted() {
    this.campaign = this.getCampaign;

    this.fetchUserList();
  },

  methods: {
    ...mapMutations(["setCampaign"]),

    setHandle(value) {
      this.handle = value;
    },

    addStreamer() {
      if (!this.handle.length) return;
      const streamer = {
        platform: "twitch",
        handle: this.handle,
        shortLink: "", // move.gg link
        stats: [],
      };

      this.campaign.handles.push(streamer);

      this.handle = "";
      this.$refs.input.$el.querySelector(".input__field").value = "";
      this.showPopup = true;
    },

    togglePopup() {
      this.showStreamerList = !this.showStreamerList;
    },

    removeStreamer(index) {
      this.campaign.handles.splice(index, 1);
    },

    nextStep() {
      if (this.campaign.handles.length) this.$router.push("/create/campaign/content");
    },

    async fetchUserList() {
      log("fetchUserList");

      const joinedStreamers = this.campaign.handles;

      let users = joinedStreamers.map((j) => ({
        checked: true,
        value: j?.handle,
      }));

      if (this.getOrganization?._id === "6104316858d6180c099272b9") {
        const HOST = process.env.VUE_APP_API_URL;
        const URL = `${HOST}/admin/users/lean`;

        const ownedStreamers = await axios
          .get(URL)
          .then((res) =>
            res?.data?.contentCreators
              ?.filter((s) => users?.findIndex((u) => u?.value === s.username) === -1)
              .map((s) => ({ checked: false, value: s.username }))
          )
          .catch((err) => {
            console.error("Error fetching user list", err);
            return [];
          });

        users = users.concat(ownedStreamers);
      } else if (this.getOrganization?._id) {
        const HOST = process.env.VUE_APP_API_URL;
        const URL = `${HOST}/organization/owned-content-creators/${this.getOrganization?._id}`;
        log(joinedStreamers);

        const ownedStreamers = await axios
          .get(URL)
          .then((res) =>
            res?.data?.streamers
              ?.filter(
                (s) =>
                  users?.findIndex((u) => u?.value === s?.username) === -1 && s?.status?.toLowerCase() === "accepted"
              )
              ?.map((s) => ({ checked: false, value: s?.username }))
          )
          .catch((err) => {
            console.error("Error fetching user list", err);
            return [];
          });
        users = users.concat(ownedStreamers);
      }

      this.userList = users;
    },

    setCheckedStreamers(streamers) {
      log("setCheckedStreamers");

      for (const streamer of streamers) {
        // Find streamers already in the handles array
        if (this.campaign?.handles?.findIndex((h) => h?.handle === streamer?.value) !== -1) {
          // Remove unchecked streamers
          if (streamer.checked === false) {
            const streamerIndex = this.campaign?.handles?.findIndex((h) => h?.handle === streamer?.value);

            this.removeStreamer(streamerIndex);
          }

          continue;
        }

        if (streamer?.checked === true) {
          const newStreamer = {
            platform: "twitch",
            handle: streamer.value,
            shortLink: "", // move.gg link
            stats: [],
          };

          this.campaign.handles.push(newStreamer);
        }
      }
    },
  },
};
</script>
